<!--
* @Description:学习中心->我的试卷
-->
<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              职业卫生在线培训
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass"
            >课程中心</router-link
          >
          <router-link active-class="active" to="/studyCenter"
            >学习中心</router-link
          >
          <router-link active-class="active" to="/examCenter"
            >考试中心</router-link
          >
<!--          <router-link active-class="active" to="/newcomerPost"-->
<!--            >新人驿站</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/liveRoom"-->
<!--            >直播大厅</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/boutiqueCourse"-->
<!--            >精品课程</router-link-->
<!--          >-->
          <router-link active-class="active" to="/trainDynamic"
            >培训动态</router-link
          >
          <router-link active-class="active" to="/helpCenter"
            >帮助中心</router-link
          >
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class="title">个人信息</div>
      <div class="formUserInfo">
        <div class="photo">
          <!-- <img :src="form.photoUrl" alt="" /> -->
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :headers="myHeaders"
              :on-success='submitSucess'
              :action="submitUrl">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div class="formInfo">
          <el-form ref="form" :model="form">
            <el-form-item label="姓 名:" label-width="80px">
              <el-col :span="11">
                <el-input v-model="form.title" placeholder="请输入"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="性 别:" label-width="80px">
              <el-select v-model="form.gender" placeholder="请选择性别">
                <el-option label="男" value="shanghai"></el-option>
                <el-option label="女" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="民 族:" label-width="80px">
              <el-select v-model="form.nationality" placeholder="请选择">
                <el-option
                  v-for="(item) in raceList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="政治面貌:" label-width="120px">
              <el-select v-model="form.zzmm" placeholder="请选择">
                <el-option
                  v-for="(item) in politicalStatusList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作单位:" label-width="120px">
              <el-col :span="11">
                <!-- <div class="nochangeValue">河南省团校</div> -->
                <el-input v-model="form.gzdw" placeholder="请输入"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="职 位:" label-width="80px">
              <el-col :span="11">
                <!-- <div class="nochangeValue">主讲人</div> -->
                <el-input v-model="form.zhiwu" placeholder="请输入"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="任职时间:" label-width="120px">
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="请选择"
                  v-model="form.rzsj"
                  style="width: 100%"
                ></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="最后学历（学位）:" label-width="250px">
              <el-col :span="11">
                <el-input v-model="form.zhxl" placeholder="请输入"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="毕业院校及专业：" label-width="250px">
              <el-col :span="11">
                <el-input v-model="form.byyx" placeholder="请输入"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="联系电话:" label-width="120px">
              <el-col :span="11"
                ><el-input v-model="form.lxdh" placeholder="请输入"></el-input
              ></el-col>
            </el-form-item>
            <el-form-item label="电子邮箱:" label-width="120px">
              <el-col :span="11"
                ><el-input v-model="form.email" placeholder="请输入"></el-input
              ></el-col>
            </el-form-item>
            <el-form-item label="个人简历：" label-width="150px">
              <el-input
                type="textarea"
                v-model="form.jianli"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="青年关心的热点问题：" label-width="300px">
              <el-input
                type="textarea"
                v-model="form.redianwenti"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="希望解答的思想理论问题：" label-width="300px">
              <el-input
                type="textarea"
                v-model="form.sixiangwenti"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div>
            <el-button class="submitBtn" type="primary" @click="creatsUserdate"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
const baseUrl = process.env.VUE_APP_SERVER_IP
const TOKEN = localStorage.getItem('token') ? localStorage.getItem('token') : ''
export default {
  name: '',
  components: {
    footerCom,
  },
  computed: {
    raceList () {
      let list = []
      this.raceStr.split('、').forEach((item) => {
        list.push(item)
      })
      return list
    },
    politicalStatusList () {
      let list = []
      this.politicalStatusStr.split('；').forEach((item) => {
        list.push(item)
      })
      return list
    },
  },
  props: {},
  watch: {},
  data () {
    return {
      submitUrl: baseUrl + '/common/upload',
      recordsData: [],
      userName: '',
      imageUrl: '',
      raceStr:
        '汉族、蒙古族、回族、藏族、维吾尔族、苗族、彝族、壮族、布依族、朝鲜族、满族、侗族、瑶族、白族、土家族、哈尼族、哈萨克族、傣族、黎族、僳僳族、佤族、畲族、高山族、拉祜族、水族、东乡族、纳西族、景颇族、柯尔克孜族、土族、达斡尔族、仫佬族、羌族、布朗族、撒拉族、毛南族、仡佬族、锡伯族、阿昌族、普米族、塔吉克族、怒族、乌孜别克族、俄罗斯族、鄂温克族、德昂族、保安族、裕固族、京族、塔塔尔族、独龙族、鄂伦春族、赫哲族、门巴族、珞巴族、基诺族',
      politicalStatusStr:
        '中共党员；中共预备党员；共青团员；民革党员；民盟盟员；民建会员；民进会员；农工党党员；致公党党员；九三学社社员；台盟盟员；无党派人士；群众',
      form: {
        title: '',
        zhiwu: '',
        gzdw: '',
        gender: '',
        nationality: '',
        zzmm: '',
        rzsj: '',
        zhxl: '',
        byyx: '',
        lxdh: '',
        email: '',
        jianli: '',
        redianwenti: '',
        sixiangwenti: '',
      },
      ossFileId: '',
      myHeaders: {'X-Access-Token': TOKEN},
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    submitSucess (response, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw)
      if (response.success) {
        this.ossFileId = response.data.ossFileId
        this.$message({
          type: 'success',
          message: '上传成功！'
        })
      }
    },
    // 提交创建
    creatsUserdate () {
      if (
        this.form.gender &&
        this.form.nationality &&
        this.form.zzmm &&
        this.form.rzsj &&
        this.form.zhxl &&
        this.form.byyx &&
        this.form.lxdh &&
        this.form.email &&
        this.form.jianli &&
        this.form.redianwenti &&
        this.form.sixiangwenti &&
        this.form.title &&
        this.form.zhiwu &&
        this.form.gzdw &&
        this.ossFileId
      ) {
        let date = {
          byyx: this.form.byyx,
          email: this.form.email,
          gender: this.form.gender == '男' ? 'MALE' : 'FEMALE',
          gzdw: this.form.gzdw,
          jianli: this.form.jianli,
          lxdh: this.form.lxdh,
          nationality: this.form.nationality,
          photo: this.ossFileId,
          redianwenti: this.form.redianwenti,
          rzsj: this.form.rzsj,
          sixiangwenti: this.form.sixiangwenti,
          title: this.form.title,
          zhiwu: this.form.zhiwu,
          zhxl: this.form.zhxl,
          zzmm: this.form.zzmm,
          courseId: this.$route.query.courseId
        }
        this.$axios.post(this.$apiUrl.addStudentCourseTxreg, date).then((response) => {
          let res = response.data
          if (res.success) {
            // 登陆成功，跳转首页
            this.$message({
              type: 'success',
              message: '提交成功'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      } else {
        this.$message.error({
          type: 'error',
          message: '请完成表单所有内容的填写!',
        })
      }
    },
  },
  mounted () {
    this.getUserInfo()
    this.imageUrl = require('../../assets/examCenterUser.png')
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
.main-content {
  width: 1240px;
  padding-bottom: 188px;
  margin: 0 auto 0;
  padding: 30px;
  padding-bottom: 188px;
  background-color: transparent;
  min-height: 500px;
  .title {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    padding: 10px;
    border-bottom: 2px solid #1c4ecc;
    width: 120px;
    margin-bottom: 23px;
  }
  .formUserInfo {
    display: flex;
    margin-bottom: 57px;
    .photo {
      width: 139px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #707070;
      img {
      width: 137px;
      height: 198px;
      }
    }
    .formInfo {
      flex: 1;
      margin-left: 71px;
    }
    .submitBtn{
      float: right;
      width: 120px;
      height: 48px;
      background: #4774DF;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
    }
  }
}
::v-deep .el-form-item__label {
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  width: 190px;
  text-align: left;
}
::v-deep .el-form-item__content {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
</style>
